import React from "react";
import "./index.css";

function Privacy() {
  return (
    <div>
      <div className="Privacy-Header">
        <h1>Privacy</h1>
        <p>
          This policy applies to all information collected or submitted on our
          Jits Journal app for iPhone and any other devices and platforms.
        </p>
      </div>
      <div className="Privacy-Header">
        <h3> // Information We Collect //</h3>
        <p>
          When creating an account, you will be asked to enter a phone number to
          create an account. Phone numbers are only used for logging in. We
          don’t send promotional SMS messages or phone calls.
          <br />
          <br />
          The app does use third party services that may collect information
          used to identify you.
          <br />
          <br />
          Link to privacy policy of third party service providers used by the
          app:
        </p>
        <div className="Privacy-Link">
          <ul>
            <li>
              <a href="https://firebase.google.com/support/privacy">Firebase</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="Privacy-Header">
        <h3> // Ads and analytics //</h3>
        <p>
          The Jits Journal app collects aggregate, anonymous statistics, such as
          the percentage of users who use particular features, to improve the
          app.
        </p>
      </div>
      <div className="Privacy-Header">
        <h3> // Information usage //</h3>
        <p>
          We use the information we collect to operate and improve our website,
          apps, and customer support. We do not share personal information with
          outside parties except to the extent necessary to accomplish Jits
          Journal’s functionality. We may disclose your information in response
          to subpoenas, court orders, or other legal requirements; to exercise
          our legal rights or defend against legal claims; to investigate,
          prevent, or take action regarding illegal activities, suspected fraud
          or abuse, violations of our policies; or to protect our rights and
          property.
          <br />
          In the future, we may sell to, buy, merge with, or partner with other
          businesses. In such transactions, user information may be among the
          transferred assets.
        </p>
      </div>
      <div className="Privacy-Header">
        <h3> // Security //</h3>
        <p>
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
        </p>
      </div>
      <div className="Privacy-Header">
        <h3> // Accessing, changing, or deleting information //</h3>
        <p>
          You may access or change your information or delete your account from
          the Jits Journal app. Deleted information may be kept in backups for
          up to 90 days. Backups are encrypted and are only accessed if needed
          for disaster recovery. Jits Journal may delete your information at any
          time and for any reason, such as technical needs, legal concerns,
          abuse prevention, removal of idle accounts, data loss, or any other
          reason.
        </p>
      </div>
      <div className="Privacy-Header">
        <h3> // Third-party links and content //</h3>
        <p>
          Jits Journal displays links to content from third-party providers.
          These have their own independent privacy policies, and we have no
          responsibility or liability for their content or activities.
        </p>
      </div>
      <div className="Privacy-Header">
        <h3> // California Online Privacy Protection Act Compliance //</h3>
        <p>
          We comply with the California Online Privacy Protection Act. We
          therefore will not distribute your personal information to outside
          parties without your consent.
        </p>
      </div>
      <div className="Privacy-Header">
        <h3> // Children’s Online Privacy Protection Act Compliance //</h3>
        <p>
          We never collect or maintain information at our website from those we
          actually know are under 13, and no part of our website is structured
          to attract anyone under 13.
        </p>
      </div>
      <div className="Privacy-Header">
        <h3> // Information for European Union Customers //</h3>
        <p>
          By using Jits Journal and providing your information, you authorize us
          to collect, use, and store your information outside of the European
          Union.
        </p>
      </div>
      <div className="Privacy-Header">
        <h3> // International Transfers of Information //</h3>
        <p>
          Information may be processed, stored, and used outside of the country
          in which you are located. Data privacy laws vary across jurisdictions,
          and different laws may be applicable to your data depending on where
          it is processed, stored, or used.
        </p>
      </div>
      <div className="Privacy-Header">
        <h3> // Your Consent //</h3>
        <p>By using our site or apps, you consent to our privacy policy.</p>
      </div>
      <div className="Privacy-Header">
        <h3> // Contacting Us //</h3>
        <p>
          If you have questions regarding this privacy policy, you may email
          adamesgie@gmail.com.
        </p>
      </div>
      <div className="Privacy-Header">
        <h3> // Changes to this policy //</h3>
        <p>
          If we decide to change our privacy policy, we will post those changes
          on this page. Summary of changes so far:
          <ul>
            <li>First published: 9/10/2019</li>
          </ul>
        </p>
      </div>
    </div>
  );
}

export default Privacy;
