import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./Home";
import Privacy from "./Privacy";
import Support from "./Support";

function AppRouter() {
  return (
    <Router>
      <div>
        <Route path="/" exact component={Home} />
        <Route path="/jitsjournal/privacy" component={Privacy} />
        <Route path="/jitsjournal/support" component={Support} />
      </div>
    </Router>
  );
}

export default AppRouter;
