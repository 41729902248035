import React from "react";
import "./index.css";

function Support() {
  return (
    <div>
      <div className="Support-Header">
        <h1>Email: adam@esgie.com</h1>
      </div>
    </div>
  );
}

export default Support;
